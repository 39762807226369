export const texts = {
  noMandate: (i: { gender: 'male' | 'female'; senate: boolean }) =>
    `${(() => {
      if (i.senate) {
        if (i.gender === 'male') return 'Senátor';
        return 'Senátorka';
      }
      if (i.gender === 'male') return 'Poslanec';
      return 'Poslankyně';
    })()} neměl${
      i.gender === 'male' ? '' : 'a'
    } mandát během žádného hlasování o tomto RESTu`,
  activity: 'Aktivita',
  votes: 'Hlasování',
  total: 'Celkově',
  actedVote: (gender: 'male' | 'female') =>
    gender === 'male' ? 'hlasoval' : 'hlasovala',
  actedActivity: (gender: 'male' | 'female') =>
    gender === 'male' ? 'se zachoval' : 'se zachovala',
  percentage: (number: number) => `${number} %`,
  positiveActivity: 'Pro přijetí funkčních zákonů',
  negativeActivity: 'Blokování a oslabování zákonů',
  rankNumeric: (rank: number) => `${rank}.`,
  rankLabelStart: 'Pořadí',
  rankLabelEnd: (count: number, chamber: 'lower' | 'senate') =>
    chamber === 'lower'
      ? `z ${count} poslanců ve sněmovně`
      : `z ${81} senátorů`,
  politicianEvaluationHeader:
    'Jak politici hlasují o protikorupčních zákonech?',
  noKeyVotes: 'Zatím neproběhla žádná klíčová hlasování.',
  detailedEvaluation: 'Podrobně',
  ourStandpointDetailed: 'NAŠE STANOVISKO PODROBNĚ: ',
  ourStandpoint: 'Postoj Rekonstrukce státu',
  weight: 'váha',
  error: {
    unknown: 'Něco se pokazilo',
    politicianNotFound: 'Politik nenalezen',
    politicianFail: 'Načítání politika selhalo',
    politicianListFail: 'Načítání seznamu politiků selhalo 🙁',
    routeNotFound: 'Zadaná cesta nebyla nalezena',
  },
  senateListHead: 'Senátorky a senátoři',
  lowerChamberListHead: 'Poslanci',

  statement: (visible: boolean) =>
    `${visible ? 'Skrýt' : 'Zobrazit'} vyjádření politika`,
};

// entrypoint to embed app
import { EmbedApp, FrameToHostMessage, HostToFrameMessage } from './embed/app';
import ResizeObserver from 'resize-observer-polyfill';
import { Router } from 'react-router-dom';
import { createMemoryHistory, createBrowserHistory } from 'history';
import { Sentry, anyWindow } from './platform';
import * as React from 'react';
import { PropsWithChildren, useReducer, useLayoutEffect } from 'react';
import { Update } from 'history';
import { createClient } from './app/apollo-client';
import { AppRegistry } from 'react-native';
import { ApolloProvider } from '@apollo/client';

const parent = anyWindow?.parent === anyWindow ? null : anyWindow?.parent;
const history = parent
  ? createMemoryHistory({
      initialEntries: [
        anyWindow?.location.pathname + anyWindow?.location.search,
      ],
    })
  : createBrowserHistory();
if (!parent && anyWindow) {
  anyWindow.document.querySelector('html').style.overflow = 'auto';
}

const postMessage = (msg: FrameToHostMessage) => {
  if (parent) {
    parent.postMessage(msg, '*');
  }
};

export function BrowserRouter({ children }: PropsWithChildren<{}>) {
  let [state, dispatch] = useReducer((_: Update, action: Update) => action, {
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(dispatch), []);

  return (
    <Router
      children={children}
      action={state.action}
      location={state.location}
      navigator={history}
    />
  );
}

function safeParse(v: string) {
  try {
    return JSON.parse(v);
  } catch {
    return {};
  }
}

function getSettings() {
  const params = new URLSearchParams(anyWindow?.location.search);
  const ret = safeParse(params.get('settings') || '{}');
  return {
    root: ret.root ?? '/',
    type: ret.type ?? '',
  };
}

(async () => {
  const apollo = await createClient({
    credentials: 'omit',
    persist: false,
  });

  const root = anyWindow?.document.getElementById('root');
  AppRegistry.registerComponent('App', () => () => (
    <BrowserRouter>
      <ApolloProvider client={apollo}>
        <EmbedApp />
      </ApolloProvider>
    </BrowserRouter>
  ));
  AppRegistry.runApplication('App', {
    initialProps: {},
    rootTag: root,
  });
  synchronizeWithParent(root);
})().catch(Sentry.captureException);

function synchronizeWithParent(root: any) {
  const embedSettings = getSettings();
  let lastSentSize = -1;
  function sendSize(force = false) {
    const size = root.clientHeight;
    if ((lastSentSize === size || !size) && !force) return;
    postMessage({ type: 'resize', height: size });
    lastSentSize = size;
  }
  function handleMessage(event: { data: any }) {
    const data = event.data as HostToFrameMessage;
    if (typeof data.type !== 'string' || data.type.startsWith('webpack')) {
      // ignore
    } else if (data.type === 'init') {
      sendSize(true);
    } else if (data.type === 'navigate') {
      if (data.path.startsWith(embedSettings.root)) {
        history.replace(
          data.path.replace(
            embedSettings.root,
            `/${embedSettings.type}${
              embedSettings.type && embedSettings.root.endsWith('/') ? '/' : ''
            }`,
          ),
          {
            fromBridge: true,
          },
        );
      } else {
        history.replace(data.path, { fromBridge: true });
      }
    } else {
      console.warn('[rest-embed] unknown message', data);
    }
  }

  history.listen(({ action, location }) => {
    if (location.state && (location.state as any).fromBridge) return;

    const to = location.pathname + location.search;

    const subPath = to.replace(`/${embedSettings.type}`, '').replace(/^\//, '');
    console.log({ to, subPath, embedSettings, action });
    const noSlash =
      embedSettings.root.endsWith('/') || subPath.startsWith('?') || !subPath;
    postMessage({
      type: action === 'REPLACE' ? 'navigate:replace' : 'navigate',
      path: embedSettings.root + (noSlash ? '' : '/') + subPath,
    });
  });

  // create react app reports this for some reason :shrug:
  // once it stops being dumb we can also remove this file from eslintrc overrides
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  anyWindow?.addEventListener('message', handleMessage, false);
  sendSize();

  if (parent) {
    const resizeObserver = new ResizeObserver(() => sendSize());
    resizeObserver.observe(root);
  }
}

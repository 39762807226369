import { colors } from './constants-legacy';
import { EmbedRoutes } from './routes';
import * as React from 'react';
import { useBariol } from './fonts';
import ErrorBoundary from './error-boundary';
import { Text } from 'react-native';
import { Global } from '@emotion/react';
import { texts } from './texts';

export type FrameToHostMessage =
  | { type: 'navigate' | 'navigate:replace'; path: string }
  | { type: 'resize'; height: number };
export type HostToFrameMessage =
  | { type: 'init' }
  | { type: 'navigate'; path: string };
export type PostMessage = (message: FrameToHostMessage) => void;

export function EmbedApp() {
  const bariol = useBariol();

  return (
    <ErrorBoundary fallback={() => <Text>{texts.error.unknown}</Text>}>
      <Global
        styles={{
          'html,body': {
            fontSize: 18,
            color: colors.black,
            backgroundColor: 'white',
            margin: 0,
            padding: 0,
            overflow: 'hidden',
          },
          '#root': { marginTop: 30, ...bariol.emotion },
          '*': { boxSizing: 'border-box' },
        }}
      />
      <EmbedRoutes />
    </ErrorBoundary>
  );
}

export const asyncStorageKeys = {
  debugEnabled: 'debug-enabled',
  apolloCachePersist: (v: 'staging' | 'production' | 'localhost') =>
    'apollo-cache-persist-' + v + '-v4',
  introspectionQueryResultData: 'introspection-query-result-data',
  lastUpdateCheck: 'last-update-check',
  backend: 'backend',
  deletedKeys: [
    'apollo-cache-persist',
    'apollo-cache-persist2',
    'navigation-preserve',
    'navigation-state',
  ],
};

import type { Sentry as NativeSentry } from './platform';
// @ts-ignore
export const anyWindow = typeof window !== 'undefined' && window;

const Raven =
  typeof anyWindow !== 'undefined' &&
  typeof (anyWindow as any).Raven !== 'undefined'
    ? (anyWindow as any).Raven
    : null;
export const AsyncStorage = {
  getItem: () => Promise.resolve(null),
  getAllKeys: () => Promise.resolve([]),
  setItem: () => Promise.reject(new Error('Not implemented')),
  removeItem: () => Promise.resolve(),
};
export const Sentry: typeof NativeSentry = {
  captureException: Raven ? Raven.captureException.bind(Raven) : () => '',
  setContext: () => {},
  setExtras: () => {},
  setUser: () => {},
};

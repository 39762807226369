import { TextStyle, StyleProp } from 'react-native';

export const colors = {
  red: '#ec3f3f',
  grey: '#efedf2',
  greyOnGrey: '#acacac',
  middleGrey: '#696969',
  darkGrey: '#32383a',
  orange: '#eba808',
  logoManual: {
    green: '#91ff33',
    blue: '#66ccff',
    lilaPurple: '#ab70ff',
    lilaPurpleDarker: '#9864e3',
  },

  black: '#232224',
  green: '#67b168',
  darkGreen: '#438350',
  white: '#ffffff',
  blue: '#25a1d6',
  yellow: '#fee64c',
  greyOnYellow: '#98892e',
};

export const sizes = {
  margin: '10px',
  fieldHeight: '36px',
  fieldHeightNumber: 36,
  narrow: 'max-width: 750px',
};

export const sizesRN = {
  margin: 10,
  fieldHeight: 36,
};

export const fontSizesRN = {
  footNote: { fontSize: 14 },
  label: { fontSize: 16 },
  regularText: { fontSize: 18 },
  largeText: { fontSize: 20 },

  contentHeading: { fontSize: 24 },
  sectionHeading: { fontSize: 32 },
  large: { fontSize: 22 },
  larger: { fontSize: 46 },
  largest: { fontSize: 50 },
};

export const useRNFieldStyle = (): StyleProp<TextStyle> => [
  {
    backgroundColor: colors.white,

    height: sizes.fieldHeight,
    padding: 0,
    paddingLeft: 14,
  },
];

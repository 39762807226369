import { anyWindow } from 'src/platform';

const getStyleElement = (() => {
  let cache: any = null;
  return () => {
    if (!anyWindow) return null;
    if (!cache) {
      cache = anyWindow.document.createElement('style');
      anyWindow.document.head.appendChild(cache);
    }
    return cache;
  };
})();

const rules = new Set<string>();
export function appendRule(rule: string) {
  if (rules.has(rule)) return;
  const style = getStyleElement();
  if (!style) return;
  rules.add(rule);
  style.sheet.insertRule(rule);
}

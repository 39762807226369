import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  Observable,
} from '@apollo/client';
import type { Operation, NextLink, FetchResult } from '@apollo/client';
import { asyncStorageKeys } from './storage/config';
import { CachePersistor } from 'apollo3-cache-persist';
import * as fragmentTypes from './queries/fragment-types-generated.json';
import { SentryLink } from 'apollo-link-sentry';
import { AsyncStorage } from 'src/platform';

export function getBackendFromSetting(
  settingBackend: string | null,
): 'staging' | 'production' | 'localhost' {
  if (settingBackend === 'staging') return 'staging';
  return 'production';
}

function getUri(backend: ReturnType<typeof getBackendFromSetting>) {
  return {
    staging: 'https://reflektor.staging.rekonstrukcestatu.cz/graphql',
    production: 'https://reflektor.rekonstrukcestatu.cz/graphql',
    localhost: 'http://147.32.119.77:3000/graphql',
  }[backend];
}

function shorten(v: any, level = 0): any {
  if (typeof v === 'object' && v) {
    return Object.fromEntries(
      Object.entries(v).map(([k, v]) => [
        k,
        typeof v !== 'object' || !v
          ? v
          : level >= 2
          ? v + ''
          : shorten(v, level + 1),
      ]),
    );
  }
  return v;
}
let debugLingEnabled = false && __DEV__;
class DebugLink extends ApolloLink {
  request(
    operation: Operation,
    forward: NextLink,
  ): Observable<FetchResult> | null {
    if (!debugLingEnabled) return forward(operation);
    return new Observable<FetchResult>((originalObserver) => {
      const subscription = forward(operation).subscribe({
        next: (result) => {
          console.log('DebugLink next', shorten(result));
          originalObserver.next(result);
        },
        complete: () => {
          console.log('DebugLink complete');
          originalObserver.complete();
        },
        error: (error) => {
          console.log('DebugLink error', shorten(error));
          originalObserver.error(error);
        },
      });

      return () => void subscription.unsubscribe();
    });
  }
}

const createLink = (uri: string, credentials: 'include' | 'omit') =>
  ApolloLink.from([
    new DebugLink(),
    new SentryLink({
      attachBreadcrumbs: {
        includeFetchResult: true,
        includeError: true,
      },
    }),
    createHttpLink({
      uri,
      credentials,
    }),
  ]);

export const createClient = async ({
  credentials,
  persist,
}: {
  persist: boolean;
  credentials: 'include' | 'omit';
}) => {
  const item = await AsyncStorage.getItem(asyncStorageKeys.backend);
  const backend = getBackendFromSetting(item);
  const uri = getUri(backend);

  const cache = new InMemoryCache({
    possibleTypes: fragmentTypes.possibleTypes,
  });

  // cleanup old apollo-cache-persist, written August 2021, deployed :shrug:
  // TODO: this can be safely deleted
  AsyncStorage.getAllKeys((err, keys) => {
    const toDelete = (keys || []).filter(
      (key) => key.startsWith('apollo-cache-persist-') && !key.endsWith('-v4'),
    );
    if (toDelete.length > 0) {
      AsyncStorage.multiRemove(toDelete).catch(() => {});
    }
  }).catch(() => {});

  const persistor = persist
    ? new CachePersistor({
        key: asyncStorageKeys.apolloCachePersist(backend),
        cache,
        storage: AsyncStorage,
      })
    : null;
  if (persistor) await persistor.restore();
  const client = new ApolloClient({
    link: createLink(uri, credentials),
    cache,
  });
  client.onResetStore(() => persistor?.purge() ?? Promise.resolve());
  return client;
};

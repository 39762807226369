import * as React from 'react';
import { useEffect, PropsWithChildren } from 'react';
import { StyleSheet, TextProps, Text } from 'react-native';

import * as bariolBoldF from './fonts/bariol-bold';
import * as bariolBoldItF from './fonts/bariol-bold-italic';
import * as bariolLightF from './fonts/bariol-light';
import * as bariolLightItF from './fonts/bariol-light-italic';
import * as bariolRegularF from './fonts/bariol-regular';
import * as bariolRegularItF from './fonts/bariol-regular-italic';
import * as bariolThinF from './fonts/bariol-thin';
import * as bariolThinItF from './fonts/bariol-thin-italic';

import * as ssF from './fonts/source-serif';
import * as ss600F from './fonts/source-serif-600';
import { appendRule } from './append-rule';

function font(
  theFont: { woff: string; woff2: string },
  family: { name: string; fallback: string },
  weight: 100 | 200 | 300 | 400 | 500 | 600 | 700,
  style: 'normal' | 'italic',
) {
  const fontFace = `
    @font-face {
        font-family: ${family.name};
        src: url('${theFont.woff2}') format('woff2'),
            url('${theFont.woff}') format('woff');;
        font-weight: ${weight};
        font-style: ${style};
        font-display: swap;
    }
  `;

  const svg = {
    fontFamily: `${family.name}`,
    fontStyle: style,
    fontWeight: `${weight}` as '100' | '200' | '300' | '400' | '500' | '600',
  };
  const rnStyles = StyleSheet.create({
    fonts: svg,
  });
  const ret = {
    style: rnStyles.fonts,
    svg,
    css: `font-weight:${svg.fontWeight};font-style:${svg.fontStyle};font-family:${svg.fontFamily};`,
    emotion: {
      ...svg,
      fontWeight: weight,
    },
  };
  function useFont() {
    useEffect(() => appendRule(fontFace), []);
    return ret;
  }
  return {
    useFont,
    Text: (props: PropsWithChildren<TextProps>) => (
      <Text {...props} style={[useFont().style, props.style]} />
    ),
  };
}

const b = { name: 'Bariol', fallback: 'sans-serif' };
const BariolBold = font(bariolBoldF, b, 700, 'normal');
const BariolBoldItalic = font(bariolBoldItF, b, 700, 'italic');
const BariolLight = font(bariolLightF, b, 300, 'normal');
const BariolLightItalic = font(bariolLightItF, b, 300, 'italic');
const Bariol = font(bariolRegularF, b, 400, 'normal');
const BariolItalic = font(bariolRegularItF, b, 400, 'italic');
const BariolThin = font(bariolThinF, b, 100, 'normal');
const BariolThinItalic = font(bariolThinItF, b, 100, 'italic');

export const useBariolBold = BariolBold.useFont;
export const useBariolBoldItalic = BariolBoldItalic.useFont;
export const useBariolLight = BariolLight.useFont;
export const useBariolLightItalic = BariolLightItalic.useFont;
export const useBariol = Bariol.useFont;
export const useBariolItalic = BariolItalic.useFont;
export const useBariolThin = BariolThin.useFont;
export const useBariolThinItalic = BariolThinItalic.useFont;

export const BariolBoldText = BariolBold.Text;
export const BariolBoldItalicText = BariolBoldItalic.Text;
export const BariolLightText = BariolLight.Text;
export const BariolLightItalicText = BariolLightItalic.Text;
export const BariolText = Bariol.Text;
export const BariolItalicText = BariolItalic.Text;
export const BariolThinText = BariolThin.Text;
export const BariolThinItalicText = BariolThinItalic.Text;

const ss = { name: 'Source Serif Pro', fallback: 'serif' };
const SourceSerif = font(ssF, ss, 400, 'normal');
const SourceSerifBold = font(ss600F, ss, 600, 'normal');

export const useSourceSerif = SourceSerif.useFont;
export const useSourceSerifBold = SourceSerifBold.useFont;

export const SourceSerifText = SourceSerif.Text;
export const SourceSerifBoldText = SourceSerifBold.Text;

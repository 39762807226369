import * as React from 'react';
import { Sentry } from 'src/platform';

export default class ErrorBoundary extends React.Component<{
  fallback?: () => React.ReactNode;
}> {
  state = { hasError: false };

  componentDidCatch(error: Error, info: any) {
    console.error({ error, info });
    Sentry.captureException(error);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback ? this.props.fallback() : null;
    }
    return this.props.children;
  }
}

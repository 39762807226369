import LoaderSpinner from './loader-spinner';
import { Routes, Route } from 'react-router-dom';
import * as React from 'react';
import { Suspense } from 'react';
import { texts } from './texts';

function NotFound() {
  return <div>{texts.error.routeNotFound}</div>;
}

const Politicians = React.lazy(
  () =>
    import(
      /* webpackChunkName: "e-politician-list" */ './route-politician-list'
    ),
);

const Politician = React.lazy(
  () => import(/* webpackChunkName: "e-politician" */ './route-politician'),
);

const Customizer = React.lazy(
  () => import(/* webpackChunkName: "e-customizer" */ './customizer'),
);

const Chart = React.lazy(
  () => import(/* webpackChunkName: "e-chart" */ './route-chart'),
);

export function EmbedRoutes() {
  return (
    <Suspense fallback={<LoaderSpinner />}>
      <Routes>
        <Route path="/" element={<Customizer />} />
        <Route path="/seznam-politiku" element={<Politicians />} />
        <Route path="/seznam-politiku/:slug" element={<Politician />} />
        <Route path="/chart/:slug" element={<Chart />} />
        <Route element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
